import { motion } from "framer-motion";
import styled from "styled-components";
import type { WrapperProps } from "./wrapper";
import { clampBuilderViewWidth } from "../../../functions/util";

export const PageWrapper = styled(motion.main)`
  margin: 0 auto;
  max-width: 100vw;
`;

export const WrapperContainer = styled(motion.section)<WrapperProps>`
  --rail-outer: 0px;
  --rail-clamp: ${clampBuilderViewWidth(5.5, 18.5)};
  --rail-inner: calc(var(--rail-clamp) - var(--rail-outer));
  --row-gap: 1rem;
  --column-gap: 0rem;
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns:
    [start-edge]
    var(--rail-outer)
    [start-outer]
    var(--rail-inner)
    [start-rail]
    repeat(3, 1fr [col-start])
    [mid]
    repeat(3, 1fr [col-start])
    [end-rail]
    var(--rail-inner)
    [end-outer]
    var(--rail-outer)
    [end-edge];
  grid-template-rows:minmax(20vw, auto)
  grid-row-gap: var(--row-gap);
  grid-column-gap: var(--column-gap);
  position: relative;
  z-index: 1;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    --column-gap: 1.5rem;
    --rail-outer: 8vw;
    grid-template-columns:
      [start-edge]
      var(--rail-outer)
      [start-outer]
      var(--rail-inner)
      [start-rail]
      repeat(6, 1fr [col-start])
      [mid]
      repeat(6, 1fr [col-start])
      [end-rail]
      var(--rail-inner)
      [end-outer]
      var(--rail-outer)
      [end-edge];
  }

  @media only screen and ${({ theme }) => theme.device.laptopLg} {
    --column-gap: 2rem;
    --rail-outer: 12vw;
  }

  @media only screen and ${({ theme }) => theme.device.desktop} {
    --rail-outer: 14vw;
  }
`;
