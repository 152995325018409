import * as React from "react";
import { SVGProps } from "react";

const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={19}
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m24.29 8.8-8.024-8-1.412 1.417 6.309 6.29H0v2h21.165l-6.311 6.294 1.412 1.416 8.024-8L25 9.51l-.71-.708Z"
      fill="#000"
    />
  </svg>
);

export default Arrow;
