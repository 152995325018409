import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={154}
    height={178}
    viewBox="0 0 154 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M154 89c0 49.153-26.47 89-77 89S0 138.153 0 89 26.47 0 77 0s77 39.847 77 89Z"
      fill="#FFC83D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.625 37.14c13.81 15.373 17.883 35.233 17.9 51.701-.017 16.469-4.09 36.329-17.9 51.703h8.28c12.949-10.027 24.029-28.431 24.095-51.599h.001v-.207H111c-.066-23.168-11.146-41.572-24.095-51.599h-8.28ZM55.714 57.467C48.47 57.468 43 62.222 43 68.351c0 6.13 5.47 10.758 12.714 10.758 7.244 0 12.861-4.628 12.861-10.758 0-6.13-5.617-10.883-12.861-10.883Zm0 41.314C48.47 98.782 43 103.535 43 109.665c0 6.129 5.47 10.757 12.714 10.757 7.244 0 12.861-4.628 12.861-10.757 0-6.13-5.617-10.883-12.861-10.883Z"
      fill="#26241F"
    />
  </svg>
);

export default SvgComponent;
