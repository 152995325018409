import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={7}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m3.5 6.5 3.464-6H.036l3.464 6Z" fill="#26241F" />
  </svg>
);

export default SvgComponent;
