import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

/**
 * Components
 */
import Link from "@helpers/link";
import { Svg } from "@components/svg";
import Wrapper from "@globals/wrapper/wrapper";
import WrapperItem, { WrapperItemProps } from "@globals/wrapper/wrapper-item";
import Text from "./type/text";

/**
 * Nav component
 */
const Nav = () => {
  const gridProps = {
    options: {
      colStart: "start-rail",
      colEnd: "end-rail",
    },
  } as WrapperItemProps;

  return (
    <>
      <NavOuter>
        <NavWrapper>
          <NavLinksWrapper {...gridProps}>
            <Link className="button" to="/">
              <Svg svg="FullWordmark" />
            </Link>
            <NavLinksGroup>
              <Text size="body">
                <Link to="/">Home</Link>
              </Text>
              <Text size="body">
                <Link to="/investments">Investments</Link>
              </Text>
            </NavLinksGroup>
          </NavLinksWrapper>
        </NavWrapper>
      </NavOuter>
    </>
  );
};

const NavOuter = styled.nav`
  position: sticky;
  width: 100%;
  z-index: 10;
  top: 0;

  &:after {
    content: "";
    position: absolute;
    // comment
    top: 0;
    left: 0;
    height: 10rem;
    width: 100%;
    z-index: 0;
    pointer-events: none;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
  }
`;

const NavWrapper = styled(Wrapper)`
  padding: 1.5rem 0;

  svg {
    fill: ${(props) => props.theme.colors.black};
    width: 10rem;
    height: 2rem;
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    padding: 2.5rem 0;
  }
`;

const NavLinksWrapper = styled(WrapperItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavLinksGroup = styled.div`
  display: inline-flex;

  p {
    margin-left: ${(props) => props.theme.layout.space[100]};
    margin-bottom: 0;
  }
`;

export default Nav;
