import React from 'react'
import { WrapperContainer } from './wrapper.styled'

export interface WrapperProps {
  children: any
}

const Wrapper = ({children, ...props}: WrapperProps) => {
  return (
    <WrapperContainer {...props}>{children}</WrapperContainer>
  )
}

export default Wrapper