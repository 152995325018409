import Link from "@components/helpers/link";
import { Svg } from "@components/svg";
import React from "react";
import styled, { css } from "styled-components";

/**
 *
 */
import { siteMetadata } from "./head";
import Text from "./type/text";
import Wrapper from "./wrapper/wrapper";
import WrapperItem, { WrapperItemProps } from "./wrapper/wrapper-item";
import { size } from "../../style/theme";

/**
 * Footer component
 */
function Footer({ pageName }: any) {
  const d = new Date();
  let year = d.getFullYear();

  const gridProps = {
    options: {
      colStart: "start-rail",
      colEnd: "end-rail",
    },
  } as WrapperItemProps;

  return (
    <FooterContainer id="footer" className={`footer__${pageName}`}>
      <Wrapper>
        <FooterRow className="links" {...gridProps}>
          <TextWrapper>
            <Link className="button" to="/">
              {/* Commenting out until pattent work is complete */}
              {/* <Svg svg="FullWordmark" /> */}
              {/* Uncomment when patent work is complete */}
              <Image src="https://images.prismic.io/happygdp/3d043b87-5551-4e6d-9d2b-cb33802915f5_smile.png?auto=compress,format&w=500" />
            </Link>
            <Text size="body">
              <Link to="/">Home</Link>
            </Text>
            <Text size="body">
              <Link to="/investments">Investments</Link>
            </Text>
          </TextWrapper>
          <TextWrapper>
            <Text size="body">
              site by{" "}
              <a href="https://john.design" target={"_blank"}>
                john.design
              </a>
            </Text>
            <Text size="body">© {year} smile gdp</Text>
          </TextWrapper>
        </FooterRow>
      </Wrapper>
    </FooterContainer>
  );
}

const Image = styled.img`
  width: 8.5rem;
  object-fit: cover;
  margin-right: 2rem;
`;

const FooterContainer = styled.footer`
  padding: 33vh 0 12vw;

  @media (min-width: ${(props) => props.theme.size.tablet}) {
    padding: 33vh 0 8vw;
  }

  &.footer__home {
    /* scroll-snap-align: end; */
  }
`;

const FooterRow = styled(WrapperItem)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

const TextWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  p {
    margin: 0;
  }

  svg {
    fill: ${(props) => props.theme.colors.black};
    width: 8.5rem; // 136
    height: 1.625rem; // 26
  }
`;

export default Footer;
