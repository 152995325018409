import * as React from "react";
import { SVGProps } from "react";

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={120}
    height={120}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.625 8.14c13.81 15.373 17.883 35.233 17.9 51.702-.017 16.468-4.09 36.328-17.9 51.702h8.28C82.854 101.517 93.935 83.113 94 59.945v-.207C93.934 36.57 82.854 18.166 69.905 8.139h-8.28ZM38.714 28.467C31.47 28.468 26 33.222 26 39.351c0 6.13 5.47 10.758 12.714 10.758 7.244 0 12.861-4.628 12.861-10.758 0-6.13-5.617-10.883-12.861-10.883Zm0 41.314C31.47 69.782 26 74.535 26 80.665c0 6.13 5.47 10.757 12.714 10.757 7.244 0 12.861-4.628 12.861-10.757 0-6.13-5.617-10.883-12.861-10.883Z"
      fill="#26241F"
    />
  </svg>
);

export default Icon;
