import { createGlobalStyle } from "styled-components";

const BaseStyles = createGlobalStyle`

  html {
    background-color: ${(props) => props.theme.colors.white};
    font-size: 16px;
  }

  html.page__home {
    scroll-behavior: smooth;
    scroll-snap-type: proximity;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y proximity;
  }

  body {
    font-family: 'GTHaptikRegular', sans-serif;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    fill: ${({ theme }) => theme.colors.black};
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
      box-sizing: border-box;
      transition-timing-function: ${(props) =>
        props.theme.animation.timingFunction.css};
  }

  main#main {
    min-height: 50vh;
  }

  *:focus-visible {
    outline: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -3px;
      left: -3px;
      bottom: 0;
      right: 0;
      border: solid 3px black;
      border-radius: 4px;
    }
  }

  pre, code {
    font-weight: 400;
    margin: 1rem 0;
  }

  hr {
    border: solid 1.5px ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.white};
    margin: 3rem 0;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    margin-left: -0.02em;
    font-feature-settings: 'ss04' on, 'ss03' on, 'ss02' on, 'ss01' on, 'salt' on;
  }

  .headline1, .headline2, .headline3, .headline4, .headline5, .headline6 {
    font-family: 'GTHaptikMedium', sans-serif;
    font-weight: 400;
    text-transform: lowercase;
  }

  .display1 {
    font-size: ${(props) => props.theme.fonts.d1};
    line-height: 110%;
    letter-spacing: -0.025em;
  }

  .h1,
  .headline1 {
    font-size: ${(props) => props.theme.fonts.h1};
    line-height: 110%;
    letter-spacing: -0.015em;
  }

  .h2,
  .headline2 {
    font-size: ${(props) => props.theme.fonts.h2};
    line-height: 110%;
    letter-spacing: -0.015em;
  }

  .h3,
  .headline3 {
    font-size: ${(props) => props.theme.fonts.h3};
    line-height: 110%;
    letter-spacing: -0.015em;
  }

  .h4,
  .headline4 {
    font-size: ${(props) => props.theme.fonts.h4};
    line-height: 115%;
    letter-spacing: 0.01em;
  }

  .h5,
  .headline5 {
    font-size: ${(props) => props.theme.fonts.h5};
    line-height: 130%;
    letter-spacing: 0.01em;
  }

  .h6,
  .headline6 {
    font-size: ${(props) => props.theme.fonts.h6};
    line-height: 130%;
    letter-spacing: 0.01em;
  }

  .eyebrow {
    font-size: ${(props) => props.theme.fonts.eyebrow};
    letter-spacing: 0.095em;
    text-transform: uppercase;
  }

  a,
  .bodyLarge,
  .body,
  .bodySmall {
    font-family: 'GTHaptikMedium', sans-serif;
    font-weight: 400;
    text-transform: lowercase;

    &.strong {
      font-weight: 500;
    }
  }

  .bodyLarge {
    font-size: ${(props) => props.theme.fonts.bodyLarge};
    line-height: 130%;
  }

  .body {
    font-size: ${(props) => props.theme.fonts.body};
    line-height: 130%;
  }

  .bodySmall {
    font-size: ${(props) => props.theme.fonts.bodySmall};
    line-height: 130%;
  }

  a {
    color: ${(props) => props.theme.colors.black};
    transition: opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css};
    opacity: 1;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        opacity: .9;
        text-decoration: underline;
      }
    }
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }
`;

export default BaseStyles;
