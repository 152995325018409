import React from "react";

/**
 * Local Components
 */
import Head from "../components/globals/head";
import Nav from "../components/globals/nav";
import Footer from "../components/globals/footer";

/**
 * Local styles
 */
import { PageWrapper } from "../components/globals/wrapper/wrapper.styled";
import { Helmet } from "react-helmet";
import { slugify } from "../functions/util";

/**
 * Layout Component
 */
export default function Layout({ children, path, ...rest }) {
  const pageName = path === "/" ? "home" : slugify(path);

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: `page__${pageName}`,
        }}
      />
      <Head />
      <Nav />
      <PageWrapper>
        <main id="main">{children}</main>
      </PageWrapper>
      <Footer pageName={pageName} />
    </>
  );
}
