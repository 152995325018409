import { clampBuilder } from "../functions/util";
import {
  ColorPlatesType,
  ColorsPlateProps,
  ColorsType,
  FontsType,
} from "./theme.types";

/**
 * size const
 *
 * All screen sizes in pixel value
 */
export const size = {
  mobileSm: "320px",
  mobile: "375px",
  mobileLg: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopLg: "1440px",
  desktop: "1920px",
  desktopLg: "2560px",
};

/**
 * device const
 *
 * All device '@media' queries sizes in pixel value
 */
export const device = {
  mobileSm: `(min-width: ${size.mobileSm})`,
  mobile: `(min-width: ${size.mobile})`,
  mobileLg: `(min-width: ${size.mobileLg})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopLg: `(min-width: ${size.laptopLg})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopLg: `(min-width: ${size.desktopLg})`,
};

/**
 * colors const
 *
 * All colors
 */
export const colors: { [key in ColorsType]: string } = {
  black: "#26241F",
  golden: "#FFC83D",
  white: "#ffffff",
  warmWhite: "#FEF9F0",
  health: "#F9CD79",
  environment: "#A0E4D8",
  food: "#DFB8C6",
  money: "#C1EC7B",
  community: "#CFDDEE",
  media: "#AACAF0",
  commerce: "#AACAF0",
};

/**
 * colorPlates const
 *
 * All colors
 */
export const colorPlates: { [key in ColorPlatesType]: ColorsPlateProps } = {
  black: {
    foreground: colors.golden as ColorsType,
    background: colors.black as ColorsType,
    border: colors.golden as ColorsType,
    icon: "golden" as ColorsType,
  },
  golden: {
    foreground: colors.black as ColorsType,
    background: colors.golden as ColorsType,
    border: colors.black as ColorsType,
    icon: "black" as ColorsType,
  },
  white: {
    foreground: colors.black as ColorsType,
    background: colors.white as ColorsType,
    border: colors.black as ColorsType,
    icon: "black" as ColorsType,
  },
};

/**
 * fonts const
 *
 * All dynamic font sizes
 * The min end of the scale uses the modular scale of 1.125 (https://www.modularscale.com/?1&em&1.125)
 * The max end of the scale uses the modular scale of 1.5 (https://www.modularscale.com/?1&em&1.5)
 */
// create types for fonts
export const fonts: { [key in FontsType]: any } = {
  d1: clampBuilder(53 / 16, 333 / 16, 375, 1920),
  h1: clampBuilder(44 / 16, 145 / 16),
  h2: clampBuilder(40 / 16, 82 / 16),
  h3: clampBuilder(32 / 16, 62 / 16),
  h4: clampBuilder(24 / 16, 42 / 16),
  h5: clampBuilder(20 / 16, 32 / 16),
  h6: clampBuilder(16 / 16, 16 / 16),
  eyebrow: clampBuilder(12 / 16, 14 / 16),
  bodyLarge: clampBuilder(18 / 16, 24 / 16),
  body: clampBuilder(16 / 16, 18 / 16),
  bodySmall: clampBuilder(12 / 16, 12 / 16),
};

/**
 * animation const
 *
 * All animation variables
 */
export const animation = {
  timingFunction: {
    js: [0.1, 0.25, 0.3, 1],
    css: `cubic-bezier(0.1, 0.25, 0.3, 1)`,
  },
  duration: {
    100: {
      js: 0.3,
      css: `300ms`,
    },
    200: {
      js: 0.5,
      css: `500ms`,
    },
    300: {
      js: 0.6,
      css: `600ms`,
    },
  },
};

/**
 * layout const
 *
 * All layout variables
 */
export const layout = {
  outerRailBase: "2rem",
  outerRailTablet: "12vw",
  outerRailDesktop: "18vw",
  space: {
    100: "1rem",
    200: "3rem",
    300: "5rem",
    400: "4vw",
    500: "8vw",
  },
};

export default {
  device: device,
  colors: colors,
  colorPlates: colorPlates,
  animation: animation,
  size: size,
  fonts: fonts,
  layout: layout,
};
