import React from "react";

/**
 * Base Layout Component
 */
import Layout from "./layout";

/**
 * Providers
 */
import { ThemeProvider } from "styled-components";
import { ContextProviderComponent } from "./context";

/**
 * Base Styles
 */
import theme from "../style/theme";
import ResetStyles from "../style/reset-styles";
import BaseStyles from "../style/base-styles";

const LayoutMain = ({ children, ...rest }) => (
  <ContextProviderComponent>
    <ThemeProvider theme={theme}>
      <ResetStyles />
      <BaseStyles />
      <Layout {...rest}>{children}</Layout>
    </ThemeProvider>
  </ContextProviderComponent>
);

export default LayoutMain;
