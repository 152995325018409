import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import { Svg } from "../svg";

interface LinkProps {
  to: string;
  children: any;
  className?: string;
  hasArrow?: boolean;
}

const LinkStyled = styled(GatsbyLink)`
  &:hover {
    .icon-wrap svg {
      transform: translateX(0rem);
    }
  }

  .icon-wrap {
    display: inline;
    padding-left: 0.5em;

    svg path {
      transform: translateY(0.05em);
    }

    svg {
      transform: translateX(-0.25rem);
      transition: transform
          ${(props) => props.theme.animation.duration[100].css}
          ${(props) => props.theme.animation.timingFunction.css},
        opacity ${(props) => props.theme.animation.duration[100].css}
          ${(props) => props.theme.animation.timingFunction.css} !important;
    }
  }
`;

const Link = ({ to, children, hasArrow, ...rest }: LinkProps) => {
  return (
    <LinkStyled to={to} {...rest}>
      {children}
      {hasArrow && <Svg svg="Arrow" />}
    </LinkStyled>
  );
};

export default Link;
