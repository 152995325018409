import React from "react";
import styled from "styled-components";

interface TextProps {
  as?: keyof JSX.IntrinsicElements;
  size?: "eyebrow" | "bodyLarge" | "body" | "bodySmall";
  chLength?: number;
  className?: string;
  children?: any;
  text?: string;
}

const Text: React.FunctionComponent<
  TextProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({
  as: Tag = "p",
  size = "body",
  className,
  children,
  chLength = 40,
  text,
  ...rest
}) => {
  if (!text && !children) return null;

  const TagComponent = styled(Tag)<TextProps>`
    margin-left: -0.025em;
    max-width: ${chLength}ch;
  `;

  return (
    <TagComponent className={`${className} ${size}`} {...rest}>
      {children || text}
    </TagComponent>
  );
};

export default Text;
