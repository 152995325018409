import * as React from "react";
import { SVGProps } from "react";

const ExternalLinkArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 21 20 2m0 0H2m18 0v19"
      stroke="#26241F"
      strokeWidth={2.206}
      strokeLinecap="round"
    />
  </svg>
);

export default ExternalLinkArrow;
