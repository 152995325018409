import React from "react";
import styled from "styled-components";

type ColumnType =
  | "auto"
  | "start-rail"
  | "start-edge"
  | "end-edge"
  | "start-outer"
  | "end-outer"
  | "start-rail"
  | "end-rail"
  | "mid"
  | "col-start 1"
  | "col-start 2"
  | "col-start 3"
  | "col-start 4"
  | "col-start 5"
  | "col-start 6"
  | "col-start 7"
  | "col-start 8"
  | "col-start 9"
  | "col-start 10"
  | "col-start 11";

interface WrapperColOptions {
  colStart?: ColumnType;
  colEnd?: ColumnType;
  rowStart?: number | "auto" | string;
  rowEnd?: number | "auto" | string;
  rowSpan?: number;
}

export interface WrapperItemProps {
  colStart?: ColumnType;
  colEnd?: ColumnType;
  rowStart?: number | "auto" | string;
  rowEnd?: number | "auto" | string;
  rowSpan?: number;
  options?: WrapperColOptions;
  mdOptions?: WrapperColOptions;
  lgOptions?: WrapperColOptions;
  className?: string;
  children: any;
}

const WrapperItem = ({
  colStart = "start-rail",
  rowStart = "auto",
  colEnd = "end-rail",
  rowEnd = "auto",
  options = {},
  mdOptions,
  lgOptions,
  className,
  children,
  ...props
}: WrapperItemProps) => {
  return (
    <WrapperItemContainer
      colStart={colStart}
      rowStart={rowStart}
      colEnd={colEnd}
      rowEnd={rowEnd}
      options={options}
      mdOptions={mdOptions || options}
      lgOptions={lgOptions || mdOptions || options}
      className={className}
      {...props}
    >
      {children}
    </WrapperItemContainer>
  );
};

const WrapperItemContainer = styled.div<WrapperItemProps>`
  grid-column-start: ${({ options, colStart }) =>
    options?.colStart || colStart};
  grid-column-end: ${({ options, colEnd }) => options?.colEnd || colEnd};
  grid-row-start: ${({ options, rowStart }) => options?.rowStart || rowStart};
  grid-row-end: ${({ options, rowEnd }) => options?.rowEnd || rowEnd};

  @media only screen and ${({ theme }) => theme.device.tablet} {
    grid-column-start: ${({ mdOptions, colStart }) =>
      mdOptions?.colStart || colStart};
    grid-column-end: ${({ mdOptions, colEnd }) => mdOptions?.colEnd || colEnd};
    grid-row-start: ${({ mdOptions, rowStart }) =>
      mdOptions?.rowStart || rowStart};
    grid-row-end: ${({ mdOptions, rowEnd }) => mdOptions?.rowEnd || rowEnd};
  }

  @media only screen and ${({ theme }) => theme.device.laptopLg} {
    grid-column-start: ${({ lgOptions, colStart }) =>
      lgOptions?.colStart || colStart};
    grid-column-end: ${({ lgOptions, colEnd }) => lgOptions?.colEnd || colEnd};
    grid-row-start: ${({ lgOptions, rowStart }) =>
      lgOptions?.rowStart || rowStart};
    grid-row-end: ${({ lgOptions, rowEnd }) => lgOptions?.rowEnd || rowEnd};
  }
`;

export default WrapperItem;
