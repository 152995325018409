import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as icon from "../export";

const Container = styled.span`
  display: inline-flex;

  svg path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`;

interface SvgProps {
  svg:
    | "Icon"
    | "IconContained"
    | "FullWordmark"
    | "PartialWordmark"
    | "Arrow"
    | "Caret"
    | "ExternalLinkArrow"
    | "X";
  color?: "black" | "white";
  className?: string;
}

function Svg({ svg = "Icon", color = "black", ...rest }: SvgProps) {
  const Icon = icon[svg];

  return (
    <Container className="icon-wrap" color={color} {...rest}>
      {svg && <Icon />}
    </Container>
  );
}

Svg.propTypes = {
  color: PropTypes.oneOf(["black", "white"]),
  svg: PropTypes.oneOf(Object.keys(icon)),
};

export default Svg;
